import React from "react"
import Layout from '../components/layout'
import {graphql, useStaticQuery} from 'gatsby'
import blogStyles from './blog.module.scss'
import BlogExcerpt from "../components/blog-excerpt"
import SEO from "../components/seo"

const BlogPage = () => {

  const data = useStaticQuery(graphql`
  query{
    allContentfulBlogPost(
      sort: {
        fields: datePublished,
        order: DESC
      }    
    ){
      edges{
        node{
          title
          slug
          excerpt
          datePublished (formatString:"MMMM Do, YYYY")
          postContent{
            json
          }
          mainImage{
            file{
              url
            }
            
            fluid(maxWidth: 500){
              ...GatsbyContentfulFluid_withWebp
            }

            localFile{
              absolutePath
              publicURL
              childImageSharp{
                fluid(maxWidth:500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
  `)

  return (
    <Layout> 
      <SEO title = "Blog" keywords={[`blog`, `reflect`, `simple things`, `bullet journal`]}/>          
      <ol className={blogStyles.posts}>
        {data.allContentfulBlogPost.edges.map((edge) => {
          return (
            <li><BlogExcerpt post={edge.node}/></li>            
          )
        })}
      </ol>
    </Layout>  
  )
}

export default BlogPage;