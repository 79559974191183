import React from 'react'
import styles from './blog-excerpt.module.scss'
import Img from "gatsby-image"
import {Link} from 'gatsby'
import BlockButton from './block-button'

const BlogExcerpt = ({post}) => {
    return (
        <div className={styles.container}>
            <div className={styles.containerLeft}>
                <Link to={`/blog/${post.slug}`}><h2>{post.title}</h2></Link>                
                <p className={styles.datePublished}>{post.datePublished}</p>
                <p className={styles.excerpt}>{post.excerpt}</p>
                <div className={styles.buttonWrapper}>                    
                    <BlockButton to={`/blog/${post.slug}`} text="Continue Reading"/>
                </div>
            </div>   
            <div className={styles.containerRight}>
                <Link to={`/blog/${post.slug}`} aria-label="Read blog post"><Img fluid={post.mainImage.localFile.childImageSharp.fluid} alt=""/></Link>
            </div>
        </div>
    )
}

export default BlogExcerpt;